
import { Box, Collapse, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { DrawerContext, DrawerValues } from '../contexts/DrawerContext';
import { DrawerNavItem } from '../interfaces/DrawerNavItem';
import { NavItem } from './NavItem';

interface LayeredNavGroupProps {
  navItemList: DrawerNavItem[],
}

export const LayeredNavGroup = ({ navItemList }: LayeredNavGroupProps) => {
  const theme = useTheme();
  const { pathname, search } = useLocation();
  const { isHighLighted, selectedNavItem, isMobile, handleNavItemClick } = useContext(DrawerContext) as DrawerValues;

  const selectedNavItemToUse = isMobile ? selectedNavItem : undefined;

  return (<>
    {navItemList.map((each) => {
      const showAsHighlighted = isHighLighted(each, pathname, search);
      const isCurrentSelected = each.translationKey === selectedNavItemToUse?.translationKey;

      const colourToUse = () => {
        if (showAsHighlighted) return theme.palette.primary[400];
        if (isCurrentSelected) return theme.palette.primary[500];
        return 'transparent';
      };

      return (
        <Box
          key={`LayeredNavGroup-${each.translationKey}`}
          borderRadius={'6px'}
          py={0}
          my={isMobile ? 1 : 0}
          bgcolor={colourToUse()}
        >
          <NavItem item={each}
            onClick={() => handleNavItemClick(each)} />
          <Collapse in={isCurrentSelected}
            sx={{
              bgcolor: theme.palette.primary[500],
              borderBottomLeftRadius: '6px',
              borderBottomRightRadius: '6px',
            }}>
            {each.children?.map((child, i) => {
              return <Box key={`LayeredNavGroup-collapse-${each.translationKey}-${i}`}>
                <NavItem item={child}
                  onClick={() => handleNavItemClick(child)} />
              </Box>;
            })}
          </Collapse>
        </Box>
      );
    })}
  </>);
};
