import { useMediaQuery } from '@mui/material';
import { Configuration, SuccessResponse_1OfGetConfigurationResponse } from '../../../../app/services/api/generated';
import { UserContext } from '../../../../components/shared/useUser';
import useNswagClient from '../../../../hooks/api/useNswagClient';
import { useContext, useEffect, useState } from 'react';
import { theme } from '../../../../theme';
import { DrawerNavItem } from '../interfaces/DrawerNavItem';
import {
  Grid01, CheckDone01, ListIcon, CheckDone02, Glasses02,
  ShoppingCart01, SwitchHorizontal01, CoinsStacked01, CoinsHand, BarChart08, Tag03, Copy06, Copy07,
  Truck02, MarkerPin01, ReceiptCheck, Home02, Truck01, FileCheck01, Trash03, Cube, Announcement02, Briefcase01, Calendar, CoinsSwap02, LifeBuoy01, PackageCheck, Settings01, User01, Users01,
} from '../../../../assets';
import { useNavigate } from 'react-router-dom';
import LanguageSelector from '../../../../pages/Dashboard/components/LanguageSelector';
import useLogError from '../../../../hooks/useLogError';

export const useDrawer = () => {
  const { getConfigurations } = useNswagClient();
  const { selectedStore, hasPermissionTo, user } = useContext(UserContext);
  const [storeConfig, setStoreConfig] = useState<Configuration[]>([]);
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);
  const [open, setOpen] = useState(!isMobile);
  const navigate = useNavigate();
  const [selectedNavItem, setSelectedNavItem] = useState<DrawerNavItem>();
  const slidingSubMenuWidth = 270;
  const { logError } = useLogError();

  const appNavItems: DrawerNavItem[] = [
    {
      canRender: true,
      translationKey: 'home',
      icon: <Home02 />,
      href: '/',
    },
    {
      canRender: false,
      translationKey: 'orderStock',
      icon: <ShoppingCart01 />,
      children: [
        {
          canRender: false,
          translationKey: 'browseProducts',
          icon: <ListIcon />,
          href: '/store/orderable-items',
        },
        {
          canRender: false,
          translationKey: 'historicalOrders',
          icon: <Truck01 />,
          href: '/store/orders-history',
        },
        {
          canRender: false,
          translationKey: 'orderViaForm',
          icon: <Grid01 />,
          href: '/store/order-form',
        },
      ],
    },
    {
      canRender: false,
      translationKey: 'receiveStock',
      icon: <Truck02 />,
      permissions: ['PurchaseOrderRead'],
      children: [
        {
          canRender: false,
          translationKey: 'purchaseOrder',
          icon: <ReceiptCheck />,
          href: '/store/po-receipt',
          permissions: ['PurchaseOrderRead'],
        }, {
          canRender: false,
          translationKey: 'invoices',
          icon: <FileCheck01 />,
          href: '/invoices',
          permissions: ['InvoiceReconciliationRead', 'InvoiceReconciliationWrite', 'InvoiceReconciliationAdmin'],
        },
      ],
    },
    {
      canRender: false,
      translationKey: 'transferStock',
      icon: <SwitchHorizontal01 />,
      permissions: ['TransferRead'],
      href: '/store/stock-transfers',
    },
    {
      canRender: false,
      translationKey: 'wasteStock',
      icon: <Trash03 />,
      href: '/store/waste-checksheets',
      permissions: ['DailyWasteCheckSheetRead'],
    },
    {
      canRender: false,
      translationKey: 'count',
      icon: <CheckDone01 />,
      permissions: ['StockCountAdmin', 'StockCountRead', 'StockLocationRead'],
      children: [
        {
          canRender: false,
          translationKey: 'stockCount',
          icon: <CheckDone02 />,
          href: '/store/stock-count',
          permissions: ['StockCountAdmin', 'StockCountRead'],
        },
        {
          canRender: false,
          translationKey: 'countZones',
          icon: <MarkerPin01 />,
          href: '/store/stock-count-zones',
          permissions: ['StockLocationRead'],
          storePermission: 'LocationsEnabled',
        },
      ],
    },
    {
      canRender: false,
      translationKey: 'transactions',
      icon: <CoinsStacked01 />,
      permissions: ['TransactionAdmin', 'TransactionRead'],
      href: '/transactions',
    },
    {
      canRender: false,
      translationKey: 'viewRecipes',
      permissions: ['RecipeRead', 'ConversionRead'],
      icon: <Glasses02 />,
      children: [
        {
          canRender: false,
          translationKey: 'viewRecipes',
          icon: <Tag03 />,
          href: '/store/recipes',
          permissions: ['RecipeRead'],
        },
        {
          canRender: false,
          translationKey: 'recipeUpdates',
          icon: <Copy06 />,
          href: '/recipe-updates',
          permissions: ['RecipeRead'],
        },
      ],
    },
    {
      canRender: false,
      translationKey: 'viewItems',
      icon: <Cube />,
      children: [
        {
          canRender: false,
          translationKey: 'viewItems',
          icon: <Cube />,
          href: '/store/products?allItems=true',
          permissions: ['RecipeRead'],
        },
        {
          canRender: false,
          translationKey: 'uomConversions',
          icon: <Copy07 />,
          href: '/uom-conversions',
          permissions: ['ConversionRead'],
        },
      ],
    },
    {
      canRender: false,
      translationKey: 'stockOnHand',
      icon: <CoinsHand />,
      permissions: ['StockRead'],
      href: '/store/stock',
    },
    {
      canRender: false,
      translationKey: 'reporting',
      icon: <BarChart08 />,
      href: '/reporting',
      permissions: ['ReportRead'],
    }];
  const [appNavItemsToUse, setAppNavItemsToUse] = useState<DrawerNavItem[]>(appNavItems);

  const systemNavItems: DrawerNavItem[] = [
    {
      canRender: true,
      translationKey: 'settings',
      icon: <Settings01 />,
      children: [
        {
          canRender: true,
          translationKey: 'manageApplicationRoles',
          icon: <Users01 />,
          href: '/manage-application-roles',
          permissions: ['RolesAdmin'],
        },
        {
          canRender: true,
          translationKey: 'countStockSchedule',
          icon: <PackageCheck />,
          href: '/stock-count-schedule',
          permissions: ['StockCountAdmin', 'StockCountRead', 'StockListRead', 'StockLocationRead', 'StockLocationWrite', 'StockCountScheduleRead', 'StockCountScheduleWrite'],
        },
        {
          canRender: true,
          translationKey: 'manageTransactions',
          icon: <CoinsSwap02 />,
          href: '/manage-transactions',
          permissions: ['TransactionAdmin'],
        },
        {
          canRender: true,
          translationKey: 'scheduledReports',
          permissions: ['AutomatedTasks'],
          icon: <Calendar />,
          href: '/scheduled-reports',
        },
        {
          canRender: true,
          translationKey: 'manageUsers',
          icon: <User01 />,
          href: '/user-management',
          permissions: ['UserAdmin'],
        },
        {
          canRender: true,
          translationKey: 'manageNotifications',
          icon: <Announcement02 />,
          href: '/manage-notifications',
          permissions: ['NotificationWrite'],
        },
        {
          canRender: true,
          translationKey: 'manageCategory',
          icon: <Briefcase01 />,
          href: '/manage-categories',
          permissions: ['CategoryAdmin'],
        },
        {
          canRender: true,
          translationKey: 'settings',
          icon: <Settings01 />,
          permissions: ['ConfigurationAdmin'],
          href: '/manage-configurations',
        },
        {
          canRender: true,
          icon: <LanguageSelector onLanguageSet={() => { }} />,
          variant: 'customIcon',
        },
      ],
    },
    {
      canRender: true,
      translationKey: 'support',
      icon: <LifeBuoy01 />,
      href: '/support',
    },
  ];
  const [systemNavItemsToUse, setSystemNavItemsToUse] = useState<DrawerNavItem[]>(systemNavItems);

  const handleNavItemClick = (item: DrawerNavItem) => {
    if (item.href || ((item.children?.length ?? 0) > 0)) {
      setSelectedNavItem((prev) => {
        if (!item?.children) return undefined;
        return prev?.translationKey === item.translationKey ? undefined : item;
      });

      if (!item.children) {
        navigate(item.href ?? '/');
      }
    }

  };

  useEffect(() => {
    if (selectedStore) {
      getConfigurations(selectedStore.franchiseName ?? '', selectedStore.storeNumber ?? '')
        .then((result: SuccessResponse_1OfGetConfigurationResponse) => {
          setStoreConfig(result.data?.configurations ?? []);
          setIfCanRender(result.data?.configurations ?? []);
        })
        .catch((error) => logError(error));
    }
  }, [selectedStore, user]);

  const hasStorePermission = (per: string | undefined, config: Configuration[]): boolean => {
    if (per === undefined) return true;

    return config.some(x => x.name === per && x.value === 'true');
  };

  const setIfCanRender = (configurations: Configuration[]) => {
    const checkPermissions = (item: DrawerNavItem): boolean => {
      // Determine if the current item can render
      const childPermissions = item.children?.flatMap((child) => child.permissions ?? []) ?? [];
      const userCanAccessPageOrSubPage =
        !item.permissions || hasPermissionTo(item.permissions) || hasPermissionTo(childPermissions);
      const storeCanAccessPage =
        !item.storePermission || hasStorePermission(item.storePermission ?? [], configurations);
      const canRender = userCanAccessPageOrSubPage && storeCanAccessPage;

      item.canRender = canRender;

      item.children?.forEach(checkPermissions);

      return item.canRender;
    };

    const updatedNavItems = appNavItemsToUse.map((item) => {
      checkPermissions(item);
      return item;
    });

    setAppNavItemsToUse(updatedNavItems);

    const updatedSystemNavItems = systemNavItemsToUse.map((item) => {
      checkPermissions(item);
      return item;
    });

    setSystemNavItemsToUse(updatedSystemNavItems);
  };

  const isHighLighted = (item: DrawerNavItem, pathname: string, search: string) => {
    const urlEquals: boolean = item.children ? item.children.some(i => i?.href === pathname) : item?.href === pathname;

    const fullUrl = pathname + search;
    let urlstarts: boolean = item.children ? item.children.some(i => fullUrl.startsWith(i?.href ?? '-')) : fullUrl.startsWith(item.href ?? '-');

    if (search === '' || item.href === '/') {
      urlstarts = false;
    }
    return urlEquals || urlstarts;
  };

  return { storeConfig, open, setOpen, isMobile, isHighLighted, selectedNavItem, setSelectedNavItem, appNavItemsToUse, handleNavItemClick, systemNavItemsToUse, slidingSubMenuWidth };
};
