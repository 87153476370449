interface EnvironmentConfig {
  apiBaseUrl: string;
  ewsCompanyId: string;
  ewsApiKey: string;
  auth0BaseUrl: string;
  auth0ClientId: string;
  auth0Audience: string;
  auth0dConnectionName: string;
  auth0AuthoriseBaseUrl: string;
  auth0LogoutBaseUrl: string;
}

const commonConfig = {
  auth0Audience: 'https://starbucks-inventory-api.orderly.eu',
  apiBaseUrl: '/api',
};

const sharedTestConfig: EnvironmentConfig = {
  ...commonConfig,
  ewsCompanyId: '12878',
  ewsApiKey: '728fb4f8-27af-425f-b753-222e76ec8f70',
  auth0BaseUrl: 'https://easywebstore-test.eu.auth0.com/oauth',
  auth0ClientId: '6xZ78H92Z6Kc5i948NtFBat1W1dOkg52',
  auth0dConnectionName: 'Azure-AD-Test',
  auth0AuthoriseBaseUrl: 'https://easywebstore-test.eu.auth0.com/authorize',
  auth0LogoutBaseUrl: 'https://easywebstore-test.eu.auth0.com/v2/logout',
};

const uatLacEnvironment: EnvironmentConfig = {
  ...sharedTestConfig,
  ewsCompanyId: '13479',
  ewsApiKey: 'e2850a69-35d2-4b01-91bf-461749d7c02a',
  auth0BaseUrl: 'https://orderly-inventory.us.auth0.com/oauth',
  auth0ClientId: 'FtuY7k5QRbOr6jsCMLO65AdX2Awqqa0A',
  auth0AuthoriseBaseUrl: 'https://orderly-inventory.us.auth0.com/authorize',
  auth0LogoutBaseUrl: 'https://orderly-inventory.us.auth0.com/v2/logout',
};

const prodEnvironment: EnvironmentConfig = {
  ...commonConfig,
  ewsCompanyId: '13190',
  ewsApiKey: '86da15a9-19be-4380-bc3e-ef718ad20230',
  auth0BaseUrl: 'https://easywebstore-prod.eu.auth0.com/oauth',
  auth0ClientId: '8wPKByOptTCVoSTGCsMr6XfrdDl1uhbt',
  auth0dConnectionName: 'Azure-AD-Prod',
  auth0AuthoriseBaseUrl: 'https://easywebstore-prod.eu.auth0.com/authorize',
  auth0LogoutBaseUrl: 'https://easywebstore-prod.eu.auth0.com/v2/logout',
};

const environmentMapping: { [hostname: string]: EnvironmentConfig } = {
  'localhost': sharedTestConfig,
  'sscm-test-emea-web.azurewebsites.net': sharedTestConfig,
  'sscm-test-emea-web-v2.azurewebsites.net': sharedTestConfig,
  'orderly-sscm-uat-emea-app.azurewebsites.net': sharedTestConfig,
  'app-uat.orderly.io': sharedTestConfig,
  'sds-uat.orderly.io': sharedTestConfig,
  'sds.orderly.io': prodEnvironment,
  'sbux-sscm-prod-emea-app.azurewebsites.net': prodEnvironment,
  'orderly-sscm-uat-lac-app-ctekfjb7g2d5dcf7.westus2-01.azurewebsites.net': uatLacEnvironment,
  'orderly-sscm-uat-lac.orderly.io': uatLacEnvironment,
  'app.orderly.io': prodEnvironment,
};
export const getApiConfig = (): EnvironmentConfig => {
  const hostname = window.location.hostname;
  return environmentMapping[hostname] ?? sharedTestConfig;
};  