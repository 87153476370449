import { DialogActions, DialogContent, DialogTitle, TextField, Button, Dialog } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type ModalProps = {
  isOpen: boolean;
  onSubmit: (rmaNumber: string) => void;
  onClose: () => void;
}

export function PurchaseOrderDetailsRmaModal(props: ModalProps) {
  const { t } = useTranslation('purchaseOrderDetails');

  const [rmaNumber, setRmaNumber] = useState('');

  const handleSave = () => {
    props.onSubmit(rmaNumber);
  };

  const handleRmaNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRmaNumber(event.target.value);
  };

  return (
    <Dialog onClose={props.onClose}
      open={props.isOpen}>
      <DialogTitle>
        {t('newCreditRequest')}
      </DialogTitle>
      <DialogContent>
        <TextField value={rmaNumber}
          onChange={handleRmaNumberChange} 
          placeholder="Customer RMA Number"/>
      </DialogContent>
      <DialogActions sx={{ m: 4 }}>
        <Button variant='secondary'
          size="lg"
          onClick={props.onClose}>
          {t('returnToVendorModal.close')}
        </Button>
        <Button variant='primary'
          size="lg"
          onClick={handleSave}>
          {t('returnToVendorModal.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
