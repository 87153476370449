import { Box, Menu, MenuItem, Typography } from '@mui/material';
import { FC, useState } from 'react';
import i18n from 'i18next';
import { useLanguageSwitcher } from '../../../hooks/useLanguageSwitcher';
import { DE, GB, ES, FR, IT, RO, PL, CZ, SK } from '../../../assets';
import { theme } from '../../../theme';
import { useTranslation } from 'react-i18next';

const languages: string[] = [
  'en-GB',
  'de-DE',
  'es-ES',
  'fr-FR',
  'it-IT',
  'ro-RO',
  'pl-PL',
  'cz-CZ',
  'sk-SK',
];

const flags: Record<string, string> = {
  de: DE,
  es: ES,
  en: GB,
  fr: FR,
  it: IT,
  ro: RO,
  pl: PL,
  cz: CZ,
  sk: SK,
};

type IconProps = {
	languageCode: string;
  isSelected?: boolean;
}

const CountryIcon: FC<IconProps> = ({ languageCode, isSelected }) => {
  const countryCode = languageCode.split('-')[0];
  return (
    <img
      src={flags[countryCode]}
      alt={countryCode}
      style={{ width: 22, height: 22, borderRadius: '50%', boxShadow: isSelected ? `0 0 4px ${theme.palette.primary.light}` : 'none', cursor: 'pointer' }}
    />
  );
};

type Props = {
  onLanguageSet: () => void;
}

const LanguageSelector: FC<Props> = ({ onLanguageSet }): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);
  const close = () => setOpen(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation('common');

  const { switchLanguage } = useLanguageSwitcher(i18n, close);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeLanguage = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const onSelect = (isoCode: string) => {
    switchLanguage(isoCode);
    onLanguageSet();
  };

  return (
    <>
      <Box onClick={onChangeLanguage}
        display="flex"
        alignItems="center"
      >
        
        <CountryIcon
          languageCode={i18n.language}
          isSelected
        />
        <Typography variant="textXS" 
          sx={{ color:  theme.palette.primary.contrastText }}
          style={{ marginLeft: '30px' }}> {t('changeLanguage')}</Typography>
      </Box>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={close}>
        {languages.map((isoCode) => (
          <MenuItem
            key={isoCode}
            onClick={() => onSelect(isoCode)}
          >
            <CountryIcon languageCode={isoCode} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguageSelector;
