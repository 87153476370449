import { Button, FormControl, Grid, InputAdornment,  SvgIcon, TextField } from '@mui/material';
import { useContext, useState } from 'react';
import { SearchSm, CheckSquare, CheckSquareBroken, Copy01 } from '../../../assets';
import { formatDateString } from '../../../utils';
import { PurchaseOrderDetailsContext, PurchaseOrderDetailsValues } from '../contexts/PurchaseOrderDetailsContext';
import { useTranslation } from 'react-i18next';
import { UserContext } from './../../../components/shared/useUser';
import { PurchaseOrderDetailsRmaModal } from './modals/PurchaseOrderDetailsRmaModal';
import  MessagesSnackbar from './../../../components/shared/MessagesSnackbar';
import { PurchaseOrderDetailsFailureModal } from './modals/PurchaseOrderDetailsFailureModal';

const PurchaseOrdersDetailsFilters = () => {
  const { poLineChange,
    searchChange,
    handlePurchaseOrderNoteChange,
    showCopyQuantityModal,
    receiveClick,
    oneClickReceiveClick,
    isReceiving,
    purchaseOrders,
    purchaseOrderNote,
    search,
    poLineSearch,
    showOneClickReceive,
    showReceive,
    isAdjusting,
    showReturnToVendor,
    isCreatingReturnToVendor,
    isReturnToVendorPendingStatus,
    isReturnToVendorDateValid,
    submitReturnToVendor,
    rmaErrorMessage,
    setRmaErrorMessage,
    searchClick,
    cancelAdjust,
    returnToVendorClick,
    returnToVendorCancelClick,
    sortedPoLines,
    clearAll } = useContext(PurchaseOrderDetailsContext) as PurchaseOrderDetailsValues;
  const  { hasPermissionTo } = useContext(UserContext);
  const { t } = useTranslation('purchaseOrderDetails');
  const [ isRmaModalOpen, setIsRmaModalOpen ] = useState(false);
  const [ isPoDetailsFailureModalOpen, setIsPoDetailsFailureModalOpen ] = useState(false);

  const handleSubmit = (rmaNumber: string) => {
    setIsRmaModalOpen(false);
    submitReturnToVendor(rmaNumber);
  };

  const validateQuantities = () => {
    const areQuantitiesValid = sortedPoLines?.some(x => x.quantity && +x.quantity > 0);
    
    if (!areQuantitiesValid) {
      setIsPoDetailsFailureModalOpen(true);
      return;
    }

    setIsRmaModalOpen(true);
  };

  return  <Grid container
    spacing={5}
    sx={{
      py: '12px',
      px: '8px',
    }} >
    <Grid item
      sm={3}
      xs={6}>
      <FormControl fullWidth>
        <TextField label={t('orderReference')}
          InputLabelProps={{ shrink: true }}
          disabled
          value={purchaseOrders?.orderNumber ?? ''}/>
      </FormControl>
    </Grid>
    <Grid item
      sm={3}
      xs={6}>
      <FormControl fullWidth>
        <TextField label={t('deliveryDate')}
          InputLabelProps={{ shrink: true }}
          disabled
          value={formatDateString(purchaseOrders?.deliveryDate ?? '')}/>
      </FormControl>
    </Grid>
    <Grid item
      sm={6}
      xs={12}>
      <FormControl fullWidth>
        <TextField label={t('receivingNote')}
          InputLabelProps={{ shrink: true }}
          value={purchaseOrderNote}
          disabled={isReceiving}
          onChange={handlePurchaseOrderNoteChange}/>
      </FormControl>
    </Grid>
    <Grid item
      sm={3}
      xs={6}>
      <FormControl fullWidth>
        <TextField label={t('itemInfo')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon><SearchSm /></SvgIcon>
              </InputAdornment>
            ),
          }}
          value={search}
          onChange={searchChange}
        />
      </FormControl>
    </Grid>
    <Grid item
      sm={3}
      xs={6}>
      <FormControl fullWidth>
        <TextField label={t('poLine')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon><SearchSm /></SvgIcon>
              </InputAdornment>
            ),
          }}
          value={poLineSearch}
          onChange={poLineChange}
        />
      </FormControl>
    </Grid>
    <Grid item
      sm={3}
      xs={12}>
      <Button variant='secondary'
        fullWidth
        onClick={clearAll}
        size='lg'>
        {t('clearAll')}
      </Button>
    </Grid>
    <Grid item
      sm={3}
      xs={12}>
      <Button variant='primary'
        fullWidth
        startIcon={<SearchSm/>}
        onClick={searchClick}
        size='lg'>
        {t('search')}
      </Button>
    </Grid>
    { showReturnToVendor && !isCreatingReturnToVendor && isReceiving && !isReturnToVendorPendingStatus && hasPermissionTo(['PurchaseOrderAdjustmentsWrite']) && isReturnToVendorDateValid &&
    <Grid item
      sm={3}
      xs={12}>
      <Button variant='primary'
        fullWidth
        onClick={returnToVendorClick}
        size='lg'>
        {t('returnToVendor')}
      </Button>
    </Grid>}
    { isCreatingReturnToVendor &&
    <Grid item
      sm={3}
      xs={12}>
      <Button variant='primary'
        fullWidth
        onClick={() => validateQuantities()}
        size='lg'>
        {t('save')}
      </Button>
    </Grid>}
    { isCreatingReturnToVendor &&
    <Grid item
      sm={3}
      xs={12}>
      <Button variant='secondary'
        fullWidth
        onClick={returnToVendorCancelClick}
        size='lg'>
        {t('cancel')}
      </Button>
    </Grid>}
    {showReceive && 
      <Grid item
        sm={4}
        xs={12}>
        <Button variant='secondary'
          fullWidth
          startIcon={<SvgIcon><Copy01 /></SvgIcon>}
          onClick={showCopyQuantityModal}
          size='lg'
          disabled={isAdjusting}>
          {t('copyOrderQuantity')}
        </Button>
      </Grid>}
    {showReceive &&
      <Grid item
        sm={4}
        xs={12}>
        <Button variant='secondary'
          fullWidth
          startIcon={<SvgIcon><CheckSquare /></SvgIcon>}
          onClick={receiveClick}
          size='lg'>
          {t('receive')}
        </Button>
      </Grid>}
    {!isReceiving && showOneClickReceive && 
      <Grid item
        sm={4}
        xs={12}>
        <Button variant='primary'
          fullWidth
          startIcon={<SvgIcon><CheckSquareBroken /></SvgIcon>}
          onClick={oneClickReceiveClick}
          size='lg'>
          {t('oneClickReceive')}
        </Button>
      </Grid>
    }
    { showReceive && isAdjusting &&
      <Grid item
        sm={4}
        xs={12}>
        <Button variant='primary'
          fullWidth
          onClick={cancelAdjust}
          size='lg'>
          {t('cancelAdjust')}
        </Button>
      </Grid>}
    <PurchaseOrderDetailsRmaModal isOpen={isRmaModalOpen}
      onClose={() => setIsRmaModalOpen(false)}
      onSubmit={handleSubmit} />
    <PurchaseOrderDetailsFailureModal isOpen={isPoDetailsFailureModalOpen}
      onClose={() => setIsPoDetailsFailureModalOpen(false)}/>
    <MessagesSnackbar
      open={!!rmaErrorMessage}
      onClose={() => setRmaErrorMessage('')}
      message={rmaErrorMessage ?? ''}
      severity="error"
      duration={null}
    />
  </Grid>;    
};

export default PurchaseOrdersDetailsFilters;