/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { getApiConfig } from '../.,/../../app/services/environment';

const apiConfig = getApiConfig();

type AuthenticationResponse = {
  access_token: string;
  error?: string;
  error_message?: string;
}

const useAuthenticationService = () => {
  const [instance] = useState(() => axios.create());

  const login = async (username: string, password: string): Promise<AuthenticationResponse> => {

    try {

      if (!username || !password) {
        throw new Error('Username and password are required.');
      }

      const data = {
        client_id: apiConfig.auth0ClientId,
        audience: apiConfig.auth0Audience,
        grant_type: 'password',
        username,
        password,
      };

      const response = await instance.post(`${apiConfig.auth0BaseUrl}/token`, data);

      return processResponse(response);

    } catch (error: any) {
      if (isAxiosError(error) && error.response) {
        return error.response;
      } else {
        throw error;
      }
    }
  };
 
  const handleAuthResponse = (): AuthenticationResponse | null => {
    const hash = window.location.hash;
    if (hash) {
      const params = new URLSearchParams(hash.substring(1));
      const accessToken = params.get('access_token');
      const error = params.get('error') ?? undefined;
      const error_message = params.get('error_description') ?? undefined;

      if (accessToken) {
        return { access_token: accessToken, error, error_message };
      }
    } 
    return null;
  };

  const processResponse = (response: AxiosResponse) => {
    const status = response.status;
    const headers = { ...response.headers };

    if (status === 400) {
      return throwException('Bad Request', status, JSON.stringify(response.data), headers, response.data);

    } else if (status === 200) {
      return Promise.resolve(response.data);

    } else if (status === 401) {
      return throwException('Unauthorized', status, JSON.stringify(response.data), headers);

    } else if (status === 403) {
      return throwException('Forbidden', status, JSON.stringify(response.data), headers);

    } else if (status !== 200 && status !== 204) {
      return throwException('An unexpected server error occurred.', status, JSON.stringify(response.data), headers);
    }

    return Promise.resolve(null);
  };

  const throwException = (message: string, status: number, response: string, headers: { [key: string]: any; }, result: any = null) => {
    if (result !== null && result !== undefined) {
      throw result;
    } else {
      throw new AuthenticationException(message, status, response, headers, null);
    }
  };

  const isAxiosError = (obj: any) => obj && obj.isAxiosError === true;

  return { login, handleAuthResponse  };
};

class AuthenticationException extends Error {
  status: number;
  response: string;
  headers: { [key: string]: any };
  result: any;
  isAuthenticationException: boolean;

  constructor(message: string, status: number, response: string, headers: { [key: string]: any; }, result: any = null) {
    super(message);
    this.status = status;
    this.response = response;
    this.headers = headers;
    this.result = result;
    this.name = 'AuthenticationException';
    this.isAuthenticationException = true;
  }
}

export default useAuthenticationService;