import { Button, Dialog, IconButton, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export interface ModalProps {
  open: boolean;
  onConfirmUrl: string;
  onClose: () => void;
  onConfirm: () => void;
  onCancel?: () => void;  
  leaveWarningTitle?: string; 
  leaveWarningContent?: string; 
}

const LeavePageModal = (props: ModalProps) => {

  const navigate = useNavigate();
  const { t } = useTranslation('common');

  const handleConfirm = () => {
    navigate(props.onConfirmUrl);
    props.onConfirm();
  };

  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel(); 
    } else {
      props.onClose(); 
    }
  };

  return (
    <Dialog open={props.open}
      onClose={props.onClose}
      maxWidth='sm'
      aria-labelledby="responsive-dialog-title"
      fullWidth>
      <DialogTitle>
        {props.leaveWarningTitle ?? t('leavePage')}
        <IconButton onClick={props.onClose}
          style={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {props.leaveWarningContent ?? t('leaveWarning')}
      </DialogContent>
      <DialogActions >
        <Button
          variant="secondary"
          onClick={handleCancel}>{t('cancel')}</Button>
        <Button variant="primary"
          onClick={handleConfirm}>
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LeavePageModal;