import { useState, useEffect } from 'react';
import { LocalizationProvider, DatePicker, DateCalendar } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import i18n from 'i18next';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/de';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/ro';

const localeMap = {
  'en-GB': 'en-gb',
  'de-DE': 'de',
  'es-ES': 'es',
  'fr-FR': 'fr',
  'it-IT': 'it',
  'ro-RO': 'ro',
  'pl-PL': 'pl',
};

type Language = keyof typeof localeMap;

const setDayjsLocale = (language: Language): string => {
  const dayjsLocale = localeMap[language] ?? 'en-gb';
  dayjs.locale(dayjsLocale);
  return dayjsLocale;
};

const setDayjsLocaleFromLocalStorage = (): string => {
  const storedLanguage = (localStorage.getItem('i18nextLng') ?? 'en-GB') as Language;
  const dayjsLocale = localeMap[storedLanguage] ?? 'en-gb';
  dayjs.locale(dayjsLocale);
  return dayjsLocale;
};

interface DatePickerLocalizedProps {
  label: string;
  value?: string | null;
  valueForDateCalendar?: Dayjs | null;
  disabled?: boolean,
  disableHighlightToday? : boolean,
  format?: string;
  useCalendar: boolean
  onChange?: (date: string | null) => void;
  onChangeDateCalendar?: (date: Dayjs | null) => void;
  shouldDisableDateCalendar?: (date: Dayjs) => boolean;
  shouldDisableDate?: (date: string) => boolean;
   translations?: {
    clearButtonLabel: string;
    todayButtonLabel: string;
    cancelButtonLabel: string;
    okButtonLabel: string;
    datePickerToolbarTitle?: string;
  };
}

const DatePickerLocalized: React.FC<DatePickerLocalizedProps> = ({
  label,
  value,
  valueForDateCalendar,
  disabled,
  disableHighlightToday,
  format = 'MM/DD/YYYY',
  useCalendar,
  onChange,
  shouldDisableDate,
  onChangeDateCalendar,
  shouldDisableDateCalendar,
  translations,

}) => {
  const [locale, setLocale] = useState<string>(setDayjsLocaleFromLocalStorage());
   
  useEffect(() => {
    const currentLanguage = i18n.language as Language;
    setDayjsLocale(currentLanguage);
    setLocale(localeMap[currentLanguage] ?? 'en-gb');
    const handleLanguageChange = (lng: Language) => {
      const newLocale = setDayjsLocale(lng);
      setLocale(newLocale);
    };
    i18n.on('languageChanged', handleLanguageChange);
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, []);

  const handleDateChange = (newValue: string | null) => { 
    if(onChange)
    {
      onChange(newValue);
    }
  };

  const handleDateChangeDateCalendar = (newValue: Dayjs | null) => {
    if(onChangeDateCalendar)
    {
      onChangeDateCalendar(newValue);
    }
  };

  const handleClear = () => { 
    handleDateChange('');
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}
      adapterLocale={locale}
      localeText={
        !useCalendar
          ? {
            clearButtonLabel: translations!.clearButtonLabel,
            todayButtonLabel: translations!.todayButtonLabel,
            cancelButtonLabel: translations!.cancelButtonLabel,
            okButtonLabel: translations!.okButtonLabel,
            datePickerToolbarTitle: translations!.datePickerToolbarTitle ?? '',
          }
          : undefined
      }
    >
      {useCalendar ? (
        <DateCalendar
          value={valueForDateCalendar || (value ? dayjs(value) : null)}
          onChange={(newValue) => {
            handleDateChangeDateCalendar(newValue);
          }}
          sx={{
            maxWidth: '100%',
            '& .Mui-selected': {
              borderRadius: '4px', 
              width: '40px',       
              height: '32px',     
              display: 'flex',     
              justifyContent: 'center', 
              alignItems: 'center',     
            },
            '& .MuiPickersDay-root': {
              fontWeight: 'bold',
            },
            '& .Mui-disabled': {
              fontWeight: 'normal',
              color: 'lightgrey',
            },
            '& .MuiYearCalendar-root':{
              width: 'auto',
            },
          }}
          shouldDisableDate={shouldDisableDateCalendar
            ? (date) => shouldDisableDateCalendar(dayjs(date))
            : undefined}
          disabled = {disabled}
          disableHighlightToday = {disableHighlightToday}
          showDaysOutsideCurrentMonth
        />
      ) : (
        <DatePicker
          label={label}
          value={value ? dayjs(value, 'YYYY-MM-DD') : null}
          onChange={(newValue) => {
            const formattedDate = newValue ? dayjs(newValue).format('YYYY-MM-DD') : '';
            handleDateChange(formattedDate);
          }}
          shouldDisableDate={(date) => {
            const dayjsDate = dayjs(date);
            const dateString = dayjsDate.format('YYYY-MM-DD');
            return shouldDisableDate ? shouldDisableDate(dateString) : false;
          }}
          format={format}
          sx={{ width: '100%' }}
          slotProps={{ textField: { error: false },  field: { clearable: true, onClear: () => handleClear  } }} 
        />
      )}
    </LocalizationProvider>
  );
};

export default DatePickerLocalized;
