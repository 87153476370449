import { DialogActions, DialogContent, DialogTitle, Button, Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
}

export function PurchaseOrderDetailsFailureModal(props: ModalProps) {
  const { t } = useTranslation('purchaseOrderDetails');

  return (
    <Dialog onClose={props.onClose}
      open={props.isOpen}>
      <DialogTitle>
        {t('purchaseOrderDetailsFailureModal.fail')}
      </DialogTitle>
      <DialogContent>
        {t('purchaseOrderDetailsFailureModal.error')}
      </DialogContent>
      <DialogActions sx={{ m: 4 }}>
        <Button variant='secondary'
          size="lg"
          onClick={props.onClose}>
          {t('purchaseOrderDetailsFailureModal.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
