import { Dialog, DialogTitle, DialogContent, Grid, Typography, DialogActions, Button, SvgIcon, Avatar, Box } from '@mui/material';
import { AlertTriangle } from '../../../assets';
import { Dispatch, SetStateAction } from 'react';
import { theme } from '../../../theme';

export const QuantityWarningDialog = (props: { setShowQuantityWarningDialog: Dispatch<SetStateAction<boolean>>, message: string, showDialog: boolean }) => {
  return <Dialog open={props.showDialog}
    onClose={() => props.setShowQuantityWarningDialog(false)}
    maxWidth='sm'
    fullWidth>
    <DialogTitle>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          sx={{
            backgroundColor: () => theme.palette.warning[100],
            color: () => theme.palette.warning[700],
            mr: 4,
          }}
        >
          <SvgIcon sx={{ width: '20px' }}>
            <AlertTriangle />
          </SvgIcon>
        </Avatar>
        <Box>{'Warning'}</Box>
      </Box>
    </DialogTitle>
    <DialogContent>
      <Grid container>
        <Grid item
          xs={12}>
          <Typography variant='textMD'>{props.message}</Typography>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions >
      <Button variant="primary"
        onClick={() => props.setShowQuantityWarningDialog(false)}>
        {'Close'}
      </Button>
    </DialogActions>
  </Dialog>;
};

