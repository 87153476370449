/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useState, useEffect } from 'react';
import { Link, Button, Grid, TextField, Typography, useMediaQuery, Box, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useLocalStorage from '../../../hooks/useLocalStorage';
import useAuthenticationService from '../../../hooks/api/useAuthenticationService';
import microsofticon from '../../../assets/images/microsoft.jpg';
import InitialStoreSelector from './InitialStoreSelector';
import { UserContext } from '../../../components/shared/useUser';
import { User } from '../../../app/services/api/generated';
import { getApiConfig } from '../.,/../../../app/services/environment';
import { jwtDecode } from 'jwt-decode';
import { useLocation } from 'react-router-dom';

const LoginForm = (props: { switchDisplay: () => void }) => {
  const { t } = useTranslation('authentication');
  const theme = useTheme();
  const { login, handleAuthResponse } = useAuthenticationService();
  const { setUserContext } = useContext(UserContext);
  const [storedValue, setValue] = useLocalStorage('token', '');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showSelectStore, setShowSelectStore] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const location = useLocation();
  const apiConfig = getApiConfig();

  const AUTH0_LOGIN_URL  = `${apiConfig.auth0AuthoriseBaseUrl}?client_id=${apiConfig.auth0ClientId}` +
      `&audience=${apiConfig.auth0Audience}&response_type=token` +
      `&connection=${apiConfig.auth0dConnectionName}&redirect_uri=${window.location.origin}/login`;

  const extractParamsFromHashOrSearch = (searchOrHash: string) => {
    const params = new URLSearchParams(searchOrHash.replace(/^#/, ''));
    return {
      error: params.get('error'),
      error_description: decodeURIComponent(params.get('error_description') ?? ''),
      access_token: params.get('access_token'),
    };
  };
 
  useEffect(() => {
    const authResponse = handleAuthResponse();
    if (authResponse?.access_token) {
      const token = authResponse.access_token; 
      const userEmail = decodeToken(token); 
      if (userEmail) {
        setValue(token);
        setUserContext({ emailAddress: email } as User, token);
        setOpenSnackbar(false);
        setShowSelectStore(true); 
      }
      else if (authResponse?.error_message) {  
        setSnackbarMessage(authResponse.error_message);
        setOpenSnackbar(true); 
      }
      else
      { 
        setSnackbarMessage('');
        setOpenSnackbar(true); 
      }
    }
  }, []);

  useEffect(() => { 
    const { error, error_description } = extractParamsFromHashOrSearch(location.hash);
    if (error) {
      setSnackbarMessage(error_description ?? '');
      setOpenSnackbar(true);
    }
  }, [location.hash]);

  const decodeToken = (token: string): string | null => {
    try {
      const decoded: any = jwtDecode(token);
      const email = decoded[apiConfig.auth0Audience+'/UserName'];
      if (email) {
        return email;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };
  
  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
    setEmailError('');
  };

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
    setPasswordError('');
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    setTimeout(() => {
      setSnackbarMessage('');
    }, 300);  
  };

  const isFormValid = () => {
    let isValid = true;
    if (!email) {
      isValid = false;
      setEmailError('Email is required');
      return;
    }
    if (!password) {
      isValid = false;
      setPasswordError('Password is required');
      return;
    }
  
    return isValid;
  };

  const loginUser = () => {
    login(email, password)
      .then((result) => {
        if (result.access_token) {
          const token = result.access_token;
          setValue(token);
          setUserContext({ emailAddress: email } as User, token);
          setOpenSnackbar(false);
          setShowSelectStore(true); 
        } else {
          setOpenSnackbar(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (isFormValid()) {
      loginUser();
    }
  };

  return (
    <>
      {
        showSelectStore && 
          <InitialStoreSelector/>
      }
      {!showSelectStore && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            width: '360px',
          }}
        >
          <Typography variant="displaySM"
            sx={{ textAlign: 'start' }}>
            {t('title')}
          </Typography>
          <Typography
            variant="textMD"
            sx={{ textAlign: 'start', paddingTop: '12px' }}
          >
            {t('welcomeMessage')}
          </Typography>
          <Box sx={{ xs: 12 }}>
            <Typography
              variant="textSM"
              sx={{
                display: 'flex',
                alignItems: 'left',
                textAlign: 'start',
                paddingTop: '32px',
                paddingBottom: '6px',
              }}
            >
              {t('email')}
            </Typography>
            <TextField
              value={email}
              onChange={handleEmailChange}
              onKeyDown={handleKeyPress}
              variant="outlined"
              placeholder="Email"
              error={!!emailError}
              helperText={emailError}
              fullWidth
              required />
          </Box>
          <Box sx={{ xs: 12 }}>
            <Typography
              variant="textSM"
              sx={{
                display: 'flex',
                alignItems: 'left',
                justifyContent: 'left',
                paddingTop: '20px',
                paddingBottom: '6px',
              }}
            >
              {t('password')}
            </Typography>
            <TextField
              value={password}
              onChange={handlePasswordChange}
              onKeyDown={handleKeyPress}
              type="password"
              placeholder="Password"
              variant="outlined"
              error={!!passwordError}
              helperText={passwordError}
              fullWidth
              required />
          </Box>
          <Grid
            container
            sx={{ display: 'flex', alignItems: 'center', paddingTop: '24px' }}
          >
            <Grid
              item
              lg={12}
              xs={12}
              sx={{ display: 'flex', justifyContent: 'start' }}
            >
              <Link sx={{ textDecoration: 'none' }}
                href="#"
                onClick={props.switchDisplay}>
                {t('forgotPassword')}
              </Link>
            </Grid>
          </Grid>
          <Grid sx={{ paddingTop: '24px' }}>
            <Button
              type="submit"
              variant="primary"
              fullWidth
              onClick={handleSubmit}
              sx={{
                backgroundColor: isMobile
                  ? theme.palette.common.black
                  : 'success[800]',
                border: 'success[800]',
                padding: '16px 10px',
                '&:hover': {
                  backgroundColor: isMobile
                    ? theme.palette.common.black
                    : 'success[800]',
                },
              }}
            >
              {t('signIn')}
            </Button>
          </Grid>
          <Grid sx={{ paddingTop: '16px' }}>
        
            <Button
              type="button"
              variant="secondary"
              startIcon={<img src={microsofticon} />}
              fullWidth
              onClick={() => window.location.href = AUTH0_LOGIN_URL}  
            >
              {t('signInWithMicrosoft')}
            </Button>
          </Grid>
          <Snackbar open={openSnackbar}
            onClose={handleCloseSnackbar}>
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={handleCloseSnackbar}
              severity="error"
              sx={{
                backgroundColor: theme.palette.common.white,
                color: theme.palette.common.black,
                borderRadius: '8px',
                padding: '25px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                '& .MuiAlert-icon': {
                  color: 'red',
                },
                '& .MuiAlert-action': {
                  marginRight: '-8px',
                },
              }}
            >
              <Typography
                variant="textSM"
                sx={{ fontWeight: 'bold', display: 'flex' }}
              >
                {' '}
                {snackbarMessage?.trim() ? snackbarMessage : t('invalidlogin')}
              </Typography>
              <Typography variant="textSM"
                sx={{ display: 'flex' }}>
                {snackbarMessage?.trim() ? '' : t('retry')}{' '}
              </Typography>
            </MuiAlert>
          </Snackbar>
        </Box>)}</>
  );
};

export default LoginForm;
